import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import ContentfulRichText from '../../components/ContentfulRichText'
import { ContentfulPage } from '../../../types/graphql-types'
import SEO from '../../components/SEO'
import LayoutBlank from '../../components/Layout/Blank'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

const Content = styled.section`
  &.has-toc {
    ol:first-of-type {
      li {
        ${tw`mb-4`}
        a {
          ${tw`block`}
        }
        p {
          ${tw`md:grid grid-cols-2 md:w-3/4 gap-4`}
        }
      }
    }
  }
  ${tw`container pt-8`}

  h2 {
    ${tw`leading-none mb-3 pb-2 md:mb-5`}
  }

  p {
    ${tw`mb-8`}
  }

  .bg-gray-300 {
    p {
      ${tw`last:mb-0`}
    }
  }

  ul {
    ${tw`list-disc ml-5 mb-8`}
  }

  ol {
    ${tw`list-decimal ml-5 mb-8`}
  }

  ul,
  ol {
    li {
      p:first-of-type {
        ${tw`mb-0`}
      }
    }
  }
`

export const query = graphql`
  query LivelyWearableTermsAppPage {
    contentfulPage(
      slug: { eq: "lively-wearable-terms-conditions/" }
      type: { eq: "legal" }
    ) {
      title
      seoTitle
      seoDescription
      type
      content {
        raw
      }
    }
  }
`

const LivelyWearableTermsApp: React.FC<Props> = ({ data }: Props) => {
  return (
    <LayoutBlank>
      <SEO
        title={data.contentfulPage.seoTitle as string}
        description={data.contentfulPage.seoDescription as string}
        noIndex={true}
      />
      <Content className="has-toc">
        <h1 className="mb-8">{data.contentfulPage.title}</h1>
        <ContentfulRichText document={data.contentfulPage.content} />
      </Content>
    </LayoutBlank>
  )
}

export default LivelyWearableTermsApp
